import { detectAnyAdblocker } from "just-detect-adblock";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Spinner, Toast } from "react-bootstrap";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isAdBlockActive } from "src/_metronic/utils/utils";
import { getMe, updateCustomMeta } from "src/app/crud/auth.crud";
import { getJobById } from "src/app/crud/data.crud";
import { updateQualification } from "src/app/crud/qualification.crud";
import LoaderStyle from "src/app/pages/sales-connect/style/LoaderStyle";
import { updateMe as updateMeAction } from "src/app/store/auth";
import { setActiveJobs, setNotification as updateNotification } from "src/app/store/data";
import { IQualification } from "src/types/Types";

import { getStorage, setStorage } from "../../../_metronic/_helpers/LocalStorageHelpers";
import { updatePaymentInfoProvided, updatePaymentInfoValidated } from "src/app/crud/googleAds.crud";
import { LoginAsContext } from "src/app/context/LoginContext";
import ReviewModal from "./ReviewModals";
import moment from "moment";

const liveTourEventCalendarUrl: any = process.env.REACT_APP_EVENT_CALENDAR;
const servicePlusEventCalendarUrl: any = process.env.REACT_APP_SERVICE_PLUS_EVENT_CALENDAR;

export default function SharedMessage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const loaderClasses = LoaderStyle();

    const [adBlockcheck, setAdblockCheck] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notification, setNotification] = useState<any>({});
    const [isUsingAdBlocker, setIsUsingAdBlocker] = useState<boolean>(false);
    const [mccSubAccount, setMccSubAccount] = useState<boolean>(false);
    const [paymentInfoProvided, setPaymentInfoProvided] = useState<boolean>(false);
    const [paymentInfoValidated, setPaymentInfoValidated] = useState<any>(undefined);
    const [removeMerchantCenterMessage, setRemoveMerchantCenterMessage] = useState<any>(false);
    const [isCrawlingStarted, setIsCrawlingStarted] = useState(false);
    const [liveOnboardingModel, setLiveOnboardingModel] = useState<boolean>(false);
    const [servicePlusModal, setServicePlusModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openNotify, setOpenNotify] = useState<boolean>(false);
    const [showChristmasBanner, setShowChristmasBanner] = useState<boolean>(false);

    const isGoogleAccountIntegrated = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.googleAdsIntegrated
            : state.auth.user.googleAdsIntegrated
    );

    const isGoogleMerchantIntegrated = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.googleMerchantIntegrated
            : state.auth.user.googleMerchantIntegrated
    );

    const isShopAccount = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent.isShopAccount : state.auth.user.isShopAccount
    );

    const merchantAccountLinkStatus = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.merchantAccountLinkStatus
            : state.auth.user.merchantAccountLinkStatus
    );

    const qualificationInfoMessage = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.qualificationInfoMessage
            : state.auth.user.qualificationInfoMessage
    );

    const liveTourAppointment = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.liveTourAppointment
            : state.auth.user.liveTourAppointment
    );

    const isBannerInfo: any = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.bannerInfo
            : state.auth.user.bannerInfo
    );

    const qualification: IQualification = useSelector((state: any) => state.activeAccount.qualification);

    const accIdx = useSelector((state: any) => state.activeAccount.idx);

    const notificationData = useSelector<{ type: string }>((state: any) => state.data.notification) as any;

    const jobsData = useSelector<{ type: string }>((state: any) => state.data.jobs) as any;

    const user = useSelector<{ type: string }>((state: any) =>
        state.auth && state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    ) as any;

    const platformList = useSelector((state: any) => state.platforms?.list);

    const { userType } = useContext(LoginAsContext);

    const qualificationInfoIdx = user.qualificationInfo.find((val) => val.idx === accIdx);

    useEffect(() => {
        if (user) {
            const googleAdsAccount = user.googleAdsAccount;

            setRemoveMerchantCenterMessage(user.removeMerchantCenterMessage);
            setIsCrawlingStarted(user.isCrawlerWorking);

            if (googleAdsAccount && googleAdsAccount.adcMcc) {
                setMccSubAccount(true);
            } else {
                setMccSubAccount(false);
            }

            if (googleAdsAccount && googleAdsAccount.hasOwnProperty("paymentInfoValidated")) {
                if (!googleAdsAccount.paymentInfoValidated && googleAdsAccount.paymentInfoProvided) {
                    setPaymentInfoValidated(googleAdsAccount.paymentInfoValidated);
                } else {
                    setPaymentInfoValidated(undefined);
                }
            } else {
                setPaymentInfoValidated(undefined);
            }

            if (googleAdsAccount && googleAdsAccount.hasOwnProperty("paymentInfoProvided")) {
                setPaymentInfoProvided(googleAdsAccount.paymentInfoProvided);
            } else {
                setPaymentInfoProvided(false);
            }

            setIsUsingAdBlocker(isAdBlockActive());
        }
    }, [user]);

    useEffect(() => {
        if (notificationData && notificationData.type) {
            setNotification(notificationData);
            setShowNotification(true);
            dispatch(updateNotification({}));
        }
    }, [notificationData, dispatch]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (Array.isArray(jobsData) && jobsData.length > 0) {
                for (let i = 0; i < jobsData.length; i++) {
                    try {
                        await getJobById(jobsData[i]._id);
                    } catch (e) {
                        const arr = jobsData.filter((j, key) => key !== i);
                        const notif = {
                            type: "success",
                            title: t("general.success"),
                            message: t("general.successMsg"),
                        };

                        if (jobsData[i].name === "websiteScraping") {
                            notif.message = t("partials.sharedMessage.website");
                        } else if (jobsData[i].name === "competitorScraping") {
                            notif.message = t("partials.sharedMessage.competitor");
                        }
                        if (jobsData[i].name !== "sendInviteProcess") {
                            dispatch(updateNotification(notif));
                        }
                        dispatch(setActiveJobs(arr));
                    }
                }
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [jobsData, dispatch, t]);

    useEffect(() => {
        const key = getStorage("adBlockbuttonhide");
        if (key === null) {
            detectAnyAdblocker().then((detected) => {
                if (detected) {
                    setAdblockCheck(detected);
                }
            });
        }
    }, []);

    const hideAdbloackNotify = () => {
        setStorage("adBlockbuttonhide", true);
        setAdblockCheck(false);
    };

    const onMerchantCancel = async () => {
        await updateCustomMeta({ key: "removeMerchantCenterMessage", value: true });
        await updateCustomMeta({ key: "isShopAccount", value: "no" });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    const notificationInfoMessageCancel = async (value) => {
        await updateCustomMeta({ key: "qualificationInfoMessage", value: value });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    // useEffect(() => {
    //     if (isGoogleAccountIntegrated && !isCrawlingStarted && qualification === undefined &&
    //         (merchantAccountLinkStatus === "active" || merchantAccountLinkStatus === "unlinked")) {
    //         history.push("/integrations/qualification");
    //     }
    //     // eslint-disable-next-line
    // }, [isGoogleAccountIntegrated, isCrawlingStarted, qualification, merchantAccountLinkStatus]);

    useCalendlyEventListener({
        onEventScheduled: async (e) => {
            setIsLoading(true);
            if (liveOnboardingModel) {
                setLiveOnboardingModel(false);
                await updateCustomMeta({ key: "liveTourAppointment", value: true });
            } else {
                setServicePlusModal(false);
            }
            await updateQualification({ key: "servicePlusAppointment", adsAccountIdx: accIdx });
            const userResponse = await getMe();
            dispatch(updateMeAction(userResponse.data));
            setIsLoading(false);
            setOpenNotify(true);
        },
    });

    const handlePaymentNotificationDismissible = async () => {
        await updatePaymentInfoProvided({ paymentInfoProvided: true });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    }

    const handlePaymentVerifyDetailsDismissible = async () => {
        await updatePaymentInfoValidated({ paymentInfoValidated: true });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    }

    const handleLiveTourDismissible = async () => {
        await updateCustomMeta({ key: "liveTourAppointment", value: true });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    const handleServicePlusDismissible = async () => {
        await updateQualification({ key: "servicePlusAppointment", adsAccountIdx: accIdx });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    useEffect(() => {
        const startDate = moment.utc("2023-12-18");
        const endDate = moment.utc("2024-01-04");
        if (moment.utc().isBetween(startDate, endDate, null, '[]')) {
            setShowChristmasBanner(true);
        }
    }, []);

    const handleRemoveBanner = async (key) => {
        await updateCustomMeta({ key: key, value: true });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    const textWidth = {
        maxWidth: 'calc(100% - 87px)'
    };
    return (
        <>
            {showChristmasBanner && (
                <Alert variant="success">{t("partials.sharedMessage.christmasMessage")}</Alert>
            )}

            <Alert
                className={`bg-blue-gradient ${adBlockcheck ? "blinkButton" : "blinkButtonStop"}`}
                onClick={() => hideAdbloackNotify()}
            >
                {t("general.adblockActive")}
            </Alert>

            {/* access token validation alert - googleAds */}
            {!isBannerInfo?.invalidGoogleAdsAccessToken &&
                platformList.connected?.googleAds && !platformList.valid?.googleAds && (
            <Link to="/integrations/google">
                {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.invalidGoogleAdsAccessToken")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.invalidGoogleAdsAccessToken")}</Alert> */}
                <Alert
                    className="bg-blue-gradient"
                    dismissible={false}
                >
                    <div style={textWidth}>{t("partials.sharedMessage.invalidGoogleAdsAccessToken")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.invalidGoogleAdsAccessToken")}
                                className="upgrades-banner-icon btn"
                                type="button"
                                style={{
                                    background: 'white',
                                    color: 'rgb(126, 130, 153)',
                                    fontSize: '13px',
                                    height: '34px',
                                    top: '50%',
                                    right: '0.5rem',
                                }}
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                            </button>
                        </>
                    )}
                </Alert>
            </Link>
            )}

            {/* access token validation alert - googleMerchant */}
            {!isBannerInfo?.invalidGoogleMerchantAccessToken &&
                platformList.connected?.googleMerchant && !platformList.valid?.googleMerchant && (
            <Link to="/integrations/merchant">
                {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.invalidGoogleMerchantAccessToken")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.invalidGoogleMerchantAccessToken")}</Alert> */}
                <Alert
                    className="bg-blue-gradient"
                    dismissible={false}
                >
                    <div style={textWidth}>{t("partials.sharedMessage.invalidGoogleMerchantAccessToken")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.invalidGoogleMerchantAccessToken")}
                                className="upgrades-banner-icon btn"
                                type="button"
                                style={{
                                    background: 'white',
                                    color: 'rgb(126, 130, 153)',
                                    fontSize: '13px',
                                    height: '34px',
                                    top: '50%',
                                    right: '0.5rem',
                                }}
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                            </button>
                        </>
                    )}
                </Alert>
            </Link>
            )}

            {/* access token validation alert - googleAnalytics */}
            {!isBannerInfo?.invalidGoogleAnalyticsAccessToken &&
                platformList.connected?.googleAnalytics && !platformList.valid?.googleAnalytics && (
            <Link to="/integrations/analytics">
                {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.invalidGoogleAnalyticsAccessToken")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.invalidGoogleAnalyticsAccessToken")}</Alert> */}
                <Alert
                    className="bg-blue-gradient"
                    dismissible={false}
                >
                    <div style={textWidth}>{t("partials.sharedMessage.invalidGoogleAnalyticsAccessToken")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.invalidGoogleAnalyticsAccessToken")}
                                className="upgrades-banner-icon btn"
                                type="button"
                                style={{
                                    background: 'white',
                                    color: 'rgb(126, 130, 153)',
                                    fontSize: '13px',
                                    height: '34px',
                                    top: '50%',
                                    right: '0.5rem',
                                }}
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                            </button>
                        </>
                    )}
                </Alert>
            </Link>
            )}

            {paymentInfoValidated !== undefined && !paymentInfoValidated ? (
            <Link to="/integrations/google">
                {/* <Alert className="bg-blue-gradient" onClose={() => handlePaymentVerifyDetailsDismissible()} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.verifyPayment")}</Alert> */}
                <Alert
                    className="bg-blue-gradient"
                    dismissible={false}
                >
                    <div style={textWidth}>{t("partials.sharedMessage.verifyPayment")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handlePaymentVerifyDetailsDismissible()}
                                className="upgrades-banner-icon btn"
                                type="button"
                                style={{
                                    background: 'white',
                                    color: 'rgb(126, 130, 153)',
                                    fontSize: '13px',
                                    height: '34px',
                                    top: '50%',
                                    right: '0.5rem',
                                }}
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                            </button>
                        </>
                    )}
                </Alert>
            </Link>
            ) : (
                ""
            )}

            {isUsingAdBlocker ? (
            // <Alert className="bg-blue-gradient" onClose={() => setIsUsingAdBlocker(false)} dismissible>
            //          {t("partials.sharedMessage.adblock")}
            //      </Alert>
            <Alert
                className="bg-blue-gradient"
                dismissible={false}
            >
                <div style={textWidth}>{t("partials.sharedMessage.adblock")}</div>

                {/* Custom close button */}
                <button
                    onClick={() => setIsUsingAdBlocker(false)}
                    className="upgrades-banner-icon btn"
                    type="button"
                    style={{
                        background: 'white',
                        color: 'rgb(126, 130, 153)',
                        fontSize: '13px',
                        height: '34px',
                        top: '50%',
                        right: '0.5rem',
                    }}
                >
                    <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                </button>
            </Alert>
            ) : (
                ""
            )}

            {qualificationInfoMessage ? (
            // <Alert className="bg-blue-gradient" onClose={() => notificationInfoMessageCancel(false)} dismissible>
            //          {t("partials.sharedMessage.qualificationMessageInfo")}
            //     </Alert>

            <Alert
                className="bg-blue-gradient"
                dismissible={false}
            >
                <div style={textWidth}>{t("partials.sharedMessage.qualificationMessageInfo")}</div>

                {/* Custom close button */}
                <button
                    onClick={() => notificationInfoMessageCancel(false)}
                    className="upgrades-banner-icon btn"
                    type="button"
                    style={{
                        background: 'white',
                        color: 'rgb(126, 130, 153)',
                        fontSize: '13px',
                        height: '34px',
                        top: '50%',
                        right: '0.5rem',
                    }}
                >
                    <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                </button>
            </Alert>

            ) : (
                ""
            )}

            {!isBannerInfo?.googleIntegration && isGoogleAccountIntegrated === false && !location.pathname.includes("get-started") ? (
            <Link to="/integrations/google">
                {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.googleIntegration")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.googleIntegration")}</Alert> */}
                <Alert
                    className="bg-blue-gradient"
                    dismissible={false}
                >
                    <div style={textWidth}>{t("partials.sharedMessage.googleIntegration")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.googleIntegration")}
                                className="upgrades-banner-icon btn"
                                type="button"
                                style={{
                                    background: 'white',
                                    color: 'rgb(126, 130, 153)',
                                    fontSize: '13px',
                                    height: '34px',
                                    top: '50%',
                                    right: '0.5rem',
                                }}
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                            </button>
                        </>
                    )}
                </Alert>
            </Link>
            ) : (
                ""
            )}
            
            {isGoogleAccountIntegrated === true && isGoogleMerchantIntegrated === false &&
                !removeMerchantCenterMessage && isShopAccount ? (
                // <Alert className="bg-blue-gradient" onClose={() => onMerchantCancel()} dismissible>
                //     <Link to="/integrations/merchant" className="cursor-pointer text-white">
                //         {t("partials.sharedMessage.googleMerchantIntegration")}
                //     </Link>
                // </Alert>
            <Link to="/integrations/merchant" className="cursor-pointer text-white">
                <Alert
                    className="bg-blue-gradient"
                    dismissible={false}
                >
                    <div style={textWidth}>{t("partials.sharedMessage.googleMerchantIntegration")}</div>
                    <button
                        onClick={() => onMerchantCancel()}
                        className="upgrades-banner-icon btn"
                        type="button"
                        style={{
                            background: 'white',
                            color: 'rgb(126, 130, 153)',
                            fontSize: '13px',
                            height: '34px',
                            top: '50%',
                            right: '0.5rem',
                        }}
                    >
                        <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                    </button>
                </Alert>
            </Link>

            ) : (
                ""
            )}

            {!isBannerInfo?.googleMerchantLinked && merchantAccountLinkStatus === "pending" ? (
                // <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.googleMerchantLinked")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.googleMerchantLinked")}</Alert>
            <Alert
                className="bg-blue-gradient"
                dismissible={false}
            >
                <div style={textWidth}>{t("partials.sharedMessage.googleMerchantLinked")}</div>

                {/* Custom close button */}
                {userType === "admin" && (
                    <>
                        <button
                            onClick={() => handleRemoveBanner("bannerInfo.googleMerchantLinked")}
                            className="upgrades-banner-icon btn"
                            type="button"
                            style={{
                                background: 'white',
                                color: 'rgb(126, 130, 153)',
                                fontSize: '13px',
                                height: '34px',
                                top: '50%',
                                right: '0.5rem',
                            }}
                        >
                            <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                        </button>
                    </>
                )}
            </Alert>
            ) : (
                ""
            )}

            {!isBannerInfo?.googleMerchantUnLinked && isGoogleMerchantIntegrated === true &&
                merchantAccountLinkStatus === "unlinked" &&
                history.location.pathname.includes("shopping") ? (
                // <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.googleMerchantUnLinked")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.googleMerchantUnLinked")}</Alert>
            <Alert
                className="bg-blue-gradient"
                dismissible={false}
            >
                <div style={textWidth}>{t("partials.sharedMessage.googleMerchantUnLinked")}</div>

                {/* Custom close button */}
                {userType === "admin" && (
                    <>
                        <button
                            onClick={() => handleRemoveBanner("bannerInfo.googleMerchantUnLinked")}
                            className="upgrades-banner-icon btn"
                            type="button"
                            style={{
                                background: 'white',
                                color: 'rgb(126, 130, 153)',
                                fontSize: '13px',
                                height: '34px',
                                top: '50%',
                                right: '0.5rem',
                            }}
                        >
                            <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                        </button>
                    </>
                )}
            </Alert>
            ) : (
                ""
            )}

            {!liveTourAppointment && !location.pathname.includes("get-started") && (
            <>
                <div style={{ position: "relative" }}>
                    <Alert
                        className="bg-blue-gradient"
                        style={{ cursor: "pointer" }}
                        onClick={() => setLiveOnboardingModel(true)}
                    >
                        <div style={textWidth}>{t("partials.sharedMessage.liveOnboardingTourMassegeInfo")}</div>
                    </Alert>
                    <button
                        className="upgrades-banner-icon btn"
                        type="button"
                        style={{
                            background: 'white',
                            color: 'rgb(126, 130, 153)',
                            fontSize: '13px',
                            height: '34px',
                            top: '50%',
                            right: '0.5rem',
                        }}
                        onClick={() => handleLiveTourDismissible()}
                    >
                        <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                    </button>
                </div>
            </>
            )}

            {qualification && qualificationInfoIdx && liveTourAppointment &&
                qualification.upgrades.includes("ServicePlus") &&
                !qualificationInfoIdx.servicePlusAppointment && (
            <div style={{ position: "relative" }}>
                <Alert
                    className="bg-blue-gradient"
                    onClick={() => setServicePlusModal(true)}
                    style={{ cursor: "pointer" }}
                >
                    <div style={textWidth}>{t("partials.sharedMessage.servicePlusMassegeInfo")}</div>
                </Alert>
                {userType === "admin" && (
                    <button
                        className="upgrades-banner-icon btn"
                        type="button"
                        style={{
                            background: 'white',
                            color: 'rgb(126, 130, 153)',
                            fontSize: '13px',
                            height: '34px',
                            top: '50%',
                            right: '0.5rem',
                        }}
                        onClick={() => handleServicePlusDismissible()}
                    >
                        <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                    </button>
                )}
            </div>
            )}

            {!isBannerInfo?.qualification && isGoogleAccountIntegrated === true &&
                !isCrawlingStarted && !qualification &&
                (merchantAccountLinkStatus === "active" || merchantAccountLinkStatus === "unlinked") ? (
            <Link to="/integrations/qualification">
                {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.qualification")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.qualificationInfoMissing")}</Alert> */}
                <Alert
                    className="bg-blue-gradient"
                    dismissible={false}
                >
                    <div style={textWidth}>{t("partials.sharedMessage.qualificationInfoMissing")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.qualification")}
                                className="upgrades-banner-icon btn"
                                type="button"
                                style={{
                                    background: 'white',
                                    color: 'rgb(126, 130, 153)',
                                    fontSize: '13px',
                                    height: '34px',
                                    top: '50%',
                                    right: '0.5rem',
                                }}
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                            </button>
                        </>
                    )}
                </Alert>
            </Link>
            ) : null}

            {mccSubAccount && !paymentInfoProvided && qualification ? (
            <Link to="/integrations/google">
                {/* <Alert className="bg-blue-gradient" onClose={() => handlePaymentNotificationDismissible()} dismissible={userType === "admin" ? true : false} >{t("integrations.google.paymentNotification")}</Alert> */}
                <Alert
                    className="bg-blue-gradient"
                    dismissible={false}
                >
                    <div style={textWidth}>{t("integrations.google.paymentNotification")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handlePaymentNotificationDismissible()}
                                className="upgrades-banner-icon btn"
                                type="button"
                                style={{
                                    background: 'white',
                                    color: 'rgb(126, 130, 153)',
                                    fontSize: '13px',
                                    height: '34px',
                                    top: '50%',
                                    right: '0.5rem',
                                }}
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.removeButton")}</span>
                            </button>
                        </>
                    )}
                </Alert>
            </Link>
            ) : (
                <></>
            )}
            {notification && notification.type ? (
                <Toast
                    className={`notif-${notification.type}`}
                    style={{ position: "fixed", top: "80%", right: 25, minWidth: 250, zIndex: 999 }}
                    onClose={() => setShowNotification(false)}
                    show={showNotification}
                    delay={8000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="mr-auto">{t(notification.title)}</strong>
                    </Toast.Header>
                    <Toast.Body>{t(notification.message)}</Toast.Body>
                </Toast>
            ) : (
                ""
            )}
            {isLoading && (
                <div className={loaderClasses.loaderWrapper}>
                    <Spinner
                        animation="border"
                        style={{ height: 40, width: 40, borderWidth: 5, color: "#5578EB" }}
                    />
                </div>
            )}
            <PopupModal
                url={liveTourEventCalendarUrl}
                rootElement={document.getElementById("root")!}
                open={liveOnboardingModel}
                onModalClose={() => setLiveOnboardingModel(false)}
                pageSettings={{
                    primaryColor: "#5D78FF"
                }}
            />
            <PopupModal
                url={servicePlusEventCalendarUrl}
                rootElement={document.getElementById("root")!}
                open={servicePlusModal}
                onModalClose={() => setServicePlusModal(false)}
                pageSettings={{
                    primaryColor: "#5D78FF"
                }}
            />
            <ReviewModal
                show={openNotify}
                onClose={() => setOpenNotify(false)}
                title={t("partials.sharedMessage.calendlyMessage")}
            />
        </>
    );
}
